body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url('https://ontallme.sirv.com/Desktop%20-%208.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  overflow-x: hidden;
}



html {
  height: 100%;
  width: 100%;


}