.App {
  text-align: center;
  font-family: "proxima-nova", sans-serif;

  /* background: #c2e59c;  */
   /* fallback for old browsers */
/* background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);   */
/* Chrome 10-25, Safari 5.1-6 */
/* background: linear-gradient(to right, #64b3f4, #c2e59c);  */
/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

 
}

html{
  height: 100%;
  

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: white;
}

 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
